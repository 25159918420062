import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import useAuth from '../../hooks/use-auth';
import { createLikeButtonMessage } from '../../services/create-like-button-message';
import {
  getIsLiked as getIsPostLiked,
  getLikeCount as getPostLikeCount,
} from '../../store/post-likes/post-likes-selectors';
import { Counter } from '../counter';
import LikeButton from '../like-button';
import { useActions, useSelector } from '../runtime-context';
import styles from './like-button-with-count.scss';

type Props = {
  className?: string;
  postId: string;
  showZero?: boolean;
  switchCounterAndButton?: boolean;
};

const useGetLikeState = (postId: string) => {
  const count = useSelector((state) => getPostLikeCount(state, postId));
  const liked = useSelector((state) => getIsPostLiked(state, postId));

  const [state, setState] = useState({ count, liked });

  useEffect(() => {
    setState({ count, liked });
  }, [count, liked]);

  return {
    ...state,
    setState,
  };
};

export const LikeButtonWithCount = ({
  className,
  showZero = false,
  switchCounterAndButton = false,
  postId,
}: Props) => {
  const actions = useActions();
  const { forPublicUser } = useAuth();

  const { count: likeCount, liked: isLiked, setState } = useGetLikeState(postId);

  const handleLikeClick = forPublicUser(() => {
    actions.incrementPostLikeCount(postId, isLiked);
    setState((prev) => {
      return {
        count: prev.count + (prev.liked ? -1 : 1),
        liked: !prev.liked,
      };
    });
  });

  const { t } = useTranslation();

  const postLikesMessage = createLikeButtonMessage({
    t,
    likeCount,
    isLiked,
    isComment: false,
  });

  return (
    <button className={classNames(styles.button, className)} onClick={handleLikeClick}>
      <span aria-live="polite" className={styles.srOnly}>
        {postLikesMessage}
      </span>
      <span
        className={classNames(
          styles.flexContainer,
          switchCounterAndButton && styles.switchCounterAndButton,
        )}
        data-hook="like-button-with-count__like-count"
      >
        <Counter
          className={classNames(styles.count, 'like-button-with-count__like-count')}
          count={likeCount}
          showZero={showZero}
          ariaHidden={true}
        />
        <LikeButton isLiked={isLiked} />
      </span>
    </button>
  );
};
