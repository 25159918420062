import React, { useState } from 'react';
import classNames from 'classnames';

import { LikeIcon } from '../icons/like-icon';
import styles from './like-button.scss';

type LikeButtonProps = {
  isLiked: boolean;
};

const LikeButton: React.FC<LikeButtonProps> = ({ isLiked }) => {
  const [isAnimationEnabled, setIsAnimationEnabled] = useState(false);

  return (
    <span
      onClick={() => setIsAnimationEnabled(!isLiked)}
      className={classNames(
        'like-button',
        styles.heart,
        { [styles.active]: isLiked },
        { [styles.isAnimationEnabled]: isAnimationEnabled },
      )}
      data-hook="like-button"
    >
      {[1, 2, 3, 4].map((pos) => (
        <span key={pos} aria-hidden className={styles[`pos${pos}`]}>
          <span className={styles.heartSparkle} />
        </span>
      ))}
      <span aria-hidden className={styles.heartRing} />
      <LikeIcon className={styles.heartBody} />
    </span>
  );
};

export default LikeButton;
